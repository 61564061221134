import { Link } from "@reach/router";
import React from "react";
import useCI from "../../hooks/use-ci";
import Nav from "../nav/nav";
import "./header.scss";

export default function Header({ noLogo = false, noNav, reload = false }) {
  let { logo, extraCss } = useCI();
  //console.log({ extraCss });
  return (
    <div className="header">
      {extraCss && <link rel="stylesheet" href={extraCss}></link>}
      {!noLogo && (
        <Link
          to={"/"}
          className="logo"
          onClick={(e) => {
            if (reload) {
              window.location.reload();

              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <img alt="logo" src={logo}></img>
        </Link>
      )}
      <div className="title"></div>
      <div className="flex1"></div>
      {!noNav && <Nav></Nav>}
    </div>
  );
}
