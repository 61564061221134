import React, { Suspense } from "react";

import "./App.scss";
import { Router } from "@reach/router";

import useUser from "./state/user";
import Home from "./screens/home";
import Login from "./screens/login";
import SingleClient from "./screens/singleClient";
import NewClient from "./screens/newClient";
//import ClientSettings from "./screens/clientSettings";
import Archiv from "./screens/archiv";
import GkHome from "./screens/gkHome";
import SinglePraxis from "./screens/singlePraxis";
import SingleGk from "./screens/singleGk";
import NewPraxis from "./screens/newPraxis";
import NewGk from "./screens/newGk";
import LarvalisHome from "./screens/larvalisHome";
import UpdateGk from "./screens/updateGk";
import UpdatePraxis from "./screens/updatePraxis";
import UpdateClient from "./screens/updateClient";
import UploadPlanView from "./screens/uploadPlanView";
import ConfirmPlanView from "./screens/confirmPlanView";
import Appointments from "./screens/appointments";
import TVView from "./screens/TVView";
import UploadScans from "./screens/uploadScans";
import Reschedule from "./screens/reschedule";
import DailyView from "./screens/dailyView";
import ResetPassword from "./screens/resetpassword";
import ForgotPassword from "./screens/forgotPassword";
import UploadKVView from "./screens/uploadKVView";
import ConfirmKVView from "./screens/confirmKVView";
import SingleStep from "./screens/singleStep";
import MoveSteps from "./screens/moveSteps";
import UpdatePassword from "./screens/UpdatePassword";

import useCI from "./hooks/use-ci";
import Laufzettel from "./screens/laufzettel";
import ReassignPraxis from "./screens/reassignPraxis";

function App() {
  let token = useUser((s) => s.token);
  let { role } = useUser((s) => s.user);

  let { extraCss } = useCI();
  //console.log({ extraCss });
  return (
    <Suspense fallback={<div>fallback!</div>}>
      <Router className={extraCss && extraCss.split(".")[0].split("/")[1]}>
        {token && role === "ADMIN" && (
          <>
            <LarvalisHome path="/" />
            <NewGk path="/createGk"></NewGk>
            <NewPraxis path="/createPraxis/:parent"></NewPraxis>
            <NewPraxis path="/createPraxis"></NewPraxis>
            <SingleGk path="/singleGk/:id" />
            <UpdateGk path="/updateGk/:id" />
            <TVView path="/tv" />
            <UpdatePraxis path="/updatePraxis/:id" />
            <UploadPlanView path="/uploadPlan/:id"></UploadPlanView>
            <UploadKVView path="/uploadKV/:id" />
            <UploadPlanView path="/uploadPlan/:id/:type"></UploadPlanView>
            <DailyView path="/daily"></DailyView>
            <SingleStep path="/step/:id"></SingleStep>
            <Laufzettel path="/laufzettel/:praxis/:id/"></Laufzettel>
            <ReassignPraxis path="/zuweisen/:id/"></ReassignPraxis>
          </>
        )}

        {token && role === "GROSSKUNDE" && (
          <>
            <GkHome path="/" />
            <DailyView path="/daily"></DailyView>
            <NewPraxis path="/createPraxis"></NewPraxis>
            <UpdatePraxis path="/updatePraxis/:id" />
            <UploadKVView path="/uploadKV/:id" />
          </>
        )}

        {token && role === "PRAXIS" && (
          <>
            <Home path="/"></Home>
            <Archiv path="/archiv"></Archiv>
          </>
        )}
        {token && (
          <>
            <SingleClient path="/client/:id"></SingleClient>
            <SinglePraxis path="/praxis/:id"></SinglePraxis>
            <NewClient path="/createClient"></NewClient>
            <UpdateClient path="/updateClient/:id"></UpdateClient>
            <NewClient path="/createClient/:id"></NewClient>
            <ConfirmPlanView path="/confirmPlan/:client/:plan"></ConfirmPlanView>
            <ConfirmKVView path="/confirmKV/:client/:plan"></ConfirmKVView>
            <Appointments path="/appointments/:client/:plan/:steps"></Appointments>
            <Appointments path="/appointments/:client/:plan/:steps/:from"></Appointments>
            <UploadScans path="/uploadScans/:client"></UploadScans>
            <Reschedule path="/reschedule/:client"></Reschedule>
            <MoveSteps path="/moveSteps/:client"></MoveSteps>
            <ResetPassword path="/resetpassword"></ResetPassword>
            <UpdatePassword path="/updatePassword"></UpdatePassword>
          </>
        )}
        {!token && (
          <>
            <Login path="/"></Login>
            <ResetPassword path="/resetpassword"></ResetPassword>
            <ForgotPassword path="/forgotPassword" />
          </>
        )}
      </Router>
    </Suspense>
  );
}

export default App;
