import { useEffect } from "react";
import useApi from "./use-api";

export default function useRequest(endpoint, data, autoRefresh = false) {
  let { exec, reset, status, result, error } = useApi({
    endpoint,

    repeat: !!autoRefresh,
  });
  useEffect(() => {
    if (status === "loading" || status === "done") {
      //dont exec again
    } else {
      exec(data);
    }
  }, [data, exec, status]);

  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(() => {
        exec(data);
      }, autoRefresh * 1000);
      return () => clearInterval(interval);
    }
  }, [autoRefresh, data, exec]);
  return { reset, status, result, error };
}
