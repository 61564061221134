import { Link, useParams } from "@reach/router";
import React from "react";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";
import "./singleClient.scss";
import UploadKV from "../atoms/uploadKV/uploadKV";

export default function UploadKVView() {
  let { id } = useParams();
  return (
    <Layout>
      <Spacer h={4}></Spacer>
      <Link to={"/client/" + id}>Zurück zum Kunde</Link>
      <Spacer h={12}></Spacer>
      <div className="siteHeader">Kostenvoranschlag hochladen</div>
      <UploadKV patientId={id} />
    </Layout>
  );
}
