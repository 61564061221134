import { useNavigate, useParams } from "@reach/router";
import moment from "moment";
import React, { useMemo } from "react";
import { DatePicker, RoundButton } from "../atoms/form/form";
import Layout from "../atoms/layout/layout";
import useApi from "../hooks/use-api";
import useForm from "../hooks/use-form";
import useRequest from "../hooks/use-request";
//import useUser from "../state/user";
import Spacer from "../atoms/spacer/spacer";

import "./reschedule.scss";

export default function Reschedule() {
  let { client } = useParams();
  client = parseInt(client);
  const nav = useNavigate();
  let { exec, status } = useApi({ endpoint: "updatestep", repeat: true });
  //let { id: userId } = useUser((s) => s.user);
  let { result: steps } = useRequest("getstepsofpatient", { id: client });
  let [form, setForm] = useForm({});

  const filteredSteps = useMemo(() => {
    return (
      steps &&
      steps.data.steps
        .filter((step) => {
          return (
            step.type === "PLAN_STEP" &&
            moment(step.dueDate).isSameOrAfter(moment(), "day")
          );
        })
        .sort((a, b) => {
          return moment(a.dueDate).unix() - moment(b.dueDate).unix();
        })
    );
  }, [steps]);
  const moveAllByDays = (days) => {
    filteredSteps.forEach((step) => {
      let oldDate = moment(form[step.id] || step.dueDate);
      let newDate = oldDate.add(days, "days");
      setForm(step.id, newDate.format("YYYY-MM-DD"));
    });
  };
  const resetAll = () => {
    filteredSteps.forEach((step) => {
      setForm(step.id, null);
    });
  };
  

  const updateAllDates = () => {
    let promiseArray = filteredSteps.map((step) => {
      if (form[step.id]) {
        let newDate = moment(form[step.id]).toDate();
        return exec({ id: step.id, dueDate: newDate });
      }
      return new Promise((resolve) => resolve());
    });
    //console.log(promiseArray);
    Promise.all(promiseArray).then(() => {
      nav("/client/" + client);
    });
  };
  return (
    <Layout>
      <Spacer h={24}></Spacer>
      <div className="flex">
        <div
          className="actionLink"
          onClick={() => {
            moveAllByDays(1);
          }}
        >
          Alle um 1 Tag verschieben
        </div>
        <div
          className="actionLink"
          onClick={() => {
            moveAllByDays(7);
          }}
        >
          Alle um 1 Woche verschieben
        </div>
        <div
          className="actionLink"
          onClick={() => {
            resetAll();
          }}
        >
          Alle zurücksetzen
        </div>
      </div>
      <Spacer h={24}></Spacer>
      <div className="row head" style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>Schritt</div>
        <div style={{ flex: 1 }}>Aktueller Termin</div>
        <div style={{ flex: 1 }}>Neuer Termin</div>
        <div style={{ flex: 1 }}>&nbsp;</div>
        <div style={{ flex: 1 }}>&nbsp;</div>
      </div>
      {filteredSteps &&
        filteredSteps.map((step) => {
          return (
            <RescheduleRow
              onChange={(date) => setForm(step.id, date)}
              key={step.id}
              step={step}
              client={client}
              value={form[step.id] || null}
            ></RescheduleRow>
          );
        })}
      <RoundButton
        color="green"
        status={status}
        onClick={() => {
          updateAllDates();
        }}
      >
        Speichern
      </RoundButton>
      <Spacer h={12}></Spacer>
      <RoundButton href={"/client/" + client}>Abbrechen</RoundButton>
    </Layout>
  );
}

function RescheduleRow({ step, client, value, onChange }) {
  //console.log({ value });
  return (
    <div className="row" style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>{step.title}</div>
      <div style={{ flex: 1 }}>{moment(step.dueDate).format("DD.MM.YYYY")}</div>
      <div style={{ flex: 1 }}>
        {value && moment(value).format("DD.MM.YYYY")}
      </div>
      <div style={{ flex: 1 }}>
        <DatePicker
          onChange={(date) => date && date!==value && onChange(date)}
          value={moment(value || step.dueDate).format("YYYY-MM-DD")}
        ></DatePicker>
      </div>
   
      <div style={{ flex: 1 }}>
        <div
          onClick={() => {
            onChange(null);
          }}
        >
          Zurücksetzen
        </div>
      </div>
    </div>
  );
}
