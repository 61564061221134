import React, { useEffect } from "react";
import useForm from "../../hooks/use-form";
import { Input, RoundButton, ButtonSelector } from "../form/form";
import "./uploadPlan.scss";

import Spacer from "../spacer/spacer";
import FileUploader from "../fileUploader/fileUploader";
import useApi from "../../hooks/use-api";
import { useNavigate, useParams } from "@reach/router";

export default function UploadPlan({ patientId }) {
  let { type } = useParams();
  let [form, setForm] = useForm({
    UKSteps: "",
    OKSteps: "",
    behandlungsziel: null,
    planPDF: null,
    optionalPDF: null,
    side: "both",
    worker: "",
    type: type || "initial",
    fromStep: "1",
  });
  useEffect(() => {
    if (["retainer", "schiene"].includes(form.type) && form.UKSteps !== "1") {
      setForm("UKSteps", "1");
    }
    if (["retainer", "schiene"].includes(form.type) && form.OKSteps !== "1") {
      setForm("OKSteps", "1");
    }
  }, [form, setForm]);

  let nav = useNavigate();

  let { exec, status } = useApi({ endpoint: "addsteptopatient", repeat: true });

  //console.log(form);
  return (
    <div className="uploadPlan box">
      <div className="title">Behandlungsplan hochladen</div>
      <Spacer h={24}></Spacer>
      <Input
        label="Bearbeiter"
        value={form.worker}
        onChange={(s) => setForm("worker", s)}
      ></Input>
      <Spacer h={24}></Spacer>
      <ButtonSelector
        value={form.type}
        onChange={(v) => {
          setForm("type", v);
        }}
        options={[
          { label: "Erstbehandlung", id: "initial" },
          { label: "Korrektur", id: "correction" },
          { label: "Folgeschiene", id: "schiene" },
          { label: "Retainer", id: "retainer" },
        ]}
      ></ButtonSelector>
      <Spacer h={24}></Spacer>
      <ButtonSelector
        value={form.side}
        onChange={(v) => {
          setForm("side", v);
        }}
        options={[
          { label: "Beide", id: "both" },
          { label: "Nur Oberkiefer", id: "upper" },
          { label: "Nur Unterkiefer", id: "lower" },
        ]}
      ></ButtonSelector>
      <Spacer h={24}></Spacer>
      {!["retainer", "schiene"].includes(form.type) && (
        <>
          {(form.side === "both" || form.side === "upper") && (
            <>
              <Input
                label="OK Behandlungsschritte"
                value={form.OKSteps}
                onChange={(v) => setForm("OKSteps", v)}
              ></Input>
              <Spacer h={24}></Spacer>{" "}
            </>
          )}
          {(form.side === "both" || form.side === "lower") && (
            <>
              <Input
                label="UK Behandlungsschritte"
                value={form.UKSteps}
                onChange={(v) => setForm("UKSteps", v)}
              ></Input>
              <Spacer h={24}></Spacer>
            </>
          )}
        </>
      )}
      {form.type === "correction" && (
        <>
          <Input
            label="Ab Schirtt:"
            value={form.fromStep}
            onChange={(v) => setForm("fromStep", v)}
          ></Input>
          <Spacer h={24}></Spacer>
        </>
      )}

      <FileUploader
        relType="PDF"
        value={form.planPDF}
        onChange={(d) => {
          //console.log({ d });
          setForm("planPDF", d);
        }}
        label={"Behandlungsplan PDF"}
      ></FileUploader>
      <Spacer h={24}></Spacer>
      <FileUploader
        relType="OPTIONAL_PDF"
        value={form.optionalPDF}
        onChange={(d) => {
          //console.log({ d });
          setForm("optionalPDF", d);
        }}
        label={"Optional 2. BP-PDF"}
      ></FileUploader>
      <Spacer h={24}></Spacer>

      <div style={{ display: "flex" }}>
        <FileUploader
          relType="TREATMENT_TARGET"
          value={form.behandlungsziel}
          onChange={(d) => {
            //console.log({ d });
            setForm("behandlungsziel", d);
          }}
          label={"Behandlungsziel"}
        ></FileUploader>
      </div>
      <Spacer h={12}></Spacer>
      <Spacer h={36}></Spacer>
      <RoundButton
        status={status}
        onClick={() => {
          form.planPDF &&
            form.planPDF.id &&
            exec({
              title: "Behandlungsplan hochgeladen",
              type: "PLAN",
              pendingTask: true,
              pending: true,

              //files: form.planPDF.id,
              patientId: Number(patientId),
              ownerId: 1,
              message: "",
              dueDate: null,
              approvalDate: null,
              trackingInfo: "",
              fileId: [
                form.planPDF.id,
                form.optionalPDF && form.optionalPDF.id,
                form.behandlungsziel && form.behandlungsziel.id,
              ].filter((a) => a),
              plan: {
                UKSteps: form.side === "upper" ? 0 : parseInt(form.UKSteps),
                OKSteps: form.side === "lower" ? 0 : parseInt(form.OKSteps),
                sides: form.side,
                type: form.type,
                worker: form.worker,
              },
            }).then((a, b, c) => {
              //if type is correction, add new steps...
              if (form.type === "correction") {
                nav(
                  `/appointments/${patientId}/${a.data.step.id}/${Math.max(
                    form.OKSteps,
                    form.UKSteps
                  )}/${form.fromStep}`
                );
              } else {
                nav("/client/" + patientId);
              }
            });
        }}
      >
        Abschicken
      </RoundButton>
    </div>
  );
}
