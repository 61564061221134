import { Link, useParams } from "@reach/router";
import React, { useEffect, useState } from "react";
import Address from "../atoms/address/address";
import ClientList from "../atoms/clientList/clientList";
import { RoundButton } from "../atoms/form/form";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";
import useRequest from "../hooks/use-request";
import useUser from "../state/user";
export default function SinglePraxis() {
  let { id } = useParams();
  id = parseInt(id);
  let { result } = useRequest("getuser", { id });
  let [praxis, setPraxis] = useState(null);
  useEffect(() => {
    if (result && result.data) {
      setPraxis(result.data.user);
    }
  }, [setPraxis, result]);

  let { role } = useUser((s) => s.user);
  //console.log(praxis);

  return (
    <Layout>
      <Spacer h={12}></Spacer>
      <div className="siteHeader">{praxis && "Praxis: " + praxis.company}</div>
      {role === "ADMIN" && (
        <>
          {praxis && praxis.parentId !== 1 && (
            <Link to={"/singleGk/" + praxis.parentId}>
              GK: {praxis.parent.company}
            </Link>
          )}
          <Spacer h={12}></Spacer>
        </>
      )}
      <div className="box praxisHead">
        <Address data={praxis}></Address>
      </div>
      <div className="box" style={{ display: "flex" }}>
        <RoundButton href={"/updatePraxis/" + id} style={{ flex: 1 }}>
          Daten aktualisieren
        </RoundButton>
        {role === "ADMIN" && (
          <>
            <Spacer w={8}></Spacer>
            <RoundButton href={"/zuweisen/" + id} style={{ flex: 1 }}>
              Praxis neu zuweisen
            </RoundButton>
          </>
        )}
      </div>
      <ClientList id={id}></ClientList>
    </Layout>
  );
}
