import React, { useState } from "react";
import "./clientList.scss";
import { FaCalendarAlt, FaList, FaPlus } from "react-icons/fa";

import Spacer from "../spacer/spacer";
import { Link, useLocation, useNavigate } from "@reach/router";
import useRequest from "../../hooks/use-request";
import { Input, RoundButton } from "../form/form";
import Calendar from "../calendar/calendar";

export default function ClientList({
  noCalendar = false,
  noButton = false,
  id,
  showArchived = false,
}) {
  let { result } = useRequest("getallpatientsofuser", { id }, 10);
  //console.log(result);
  const location = useLocation();
  let nav = useNavigate();
  let [archived, setArchived] = useState(showArchived);

  let patients =
    result && result.data && result.data.patients ? result.data.patients : [];
  //console.log(patients);
  patients = patients.filter((p) => p.archived === archived);
  let [search, setSearch] = useState("");

  return (
    <div className="clientList">
      <div className="viewButtons">
        {!noButton && (
          <>
            <RoundButton
              style={{ flex: 1 }}
              href={"/createClient/" + (id ? id : "")}
            >
              <FaPlus></FaPlus>
              <Spacer w={8}></Spacer>Neuer Auftrag
            </RoundButton>
            <Spacer w={8}></Spacer>
          </>
        )}
        <RoundButton
          style={{ flex: 1 }}
          onClick={() => {
            setArchived(!archived);
          }}
        >
          {archived ? "Aktuelle Aufträge" : "Archivierte Aufträge"}
        </RoundButton>
        {!noCalendar && (
          <>
            <RoundButton
              onClick={() => nav("#list")}
              active={location.hash !== "#calendar"}
            >
              <FaList></FaList>
            </RoundButton>
            <RoundButton
              onClick={() => nav("#calendar")}
              active={location.hash === "#calendar"}
            >
              <FaCalendarAlt></FaCalendarAlt>
            </RoundButton>
          </>
        )}
      </div>
      {location.hash === "#calendar" && (
        <>
          <Spacer h={12}></Spacer>
          <Calendar id={id || undefined}></Calendar>
        </>
      )}
      {location.hash !== "#calendar" && (
        <>
          <div className="search">
            <Input placeholder={"Suchen"} value={search} onChange={setSearch} />
          </div>
          {patients
            .filter((p) => {
              return (
                (
                  p.firstName +
                  " " +
                  p.lastName +
                  " " +
                  p.firstName +
                  " " +
                  p.id +
                  " " +
                  p.cid
                )
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1
              );
            })
            .map((p) => {
              return (
                <ClientListItem
                  key={p.id}
                  step={p.steps[0]}
                  name={p.firstName + " " + p.lastName}
                  id={p.id}
                  cid={p.cid}
                ></ClientListItem>
              );
            })}
        </>
      )}
    </div>
  );
}

export function ClientListItem({
  id = "",
  cid = "",
  name = "",
  step = null,
  important = false,
  noClick,
}) {
  //console.log(step);
  return (
    <Link
      to={"/client/" + id}
      className={
        "clientListItem " +
        (important ? " important " : " ") +
        (noClick ? " noClick " : "")
      }
    >
      <div className="clientNum">
        {id} / #{cid}
      </div>
      <div className="clientName">{name}</div>
      {step && <div className={"clientStatus step"}>{step && step.title}</div>}
      {/* <div className="nextAssignment"> */}
      {/* <FaCalendarAlt></FaCalendarAlt> */}
      {/* <Spacer w={8}></Spacer>22.07.2021 */}
      {/* </div> */}
    </Link>
  );
}
