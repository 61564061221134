import React from "react";
import Layout from "../atoms/layout/layout";
import PraxisList from "../atoms/praxisList/praxisList";
import Spacer from "../atoms/spacer/spacer";
import { AttentionItems } from "./dailyView";
export default function GkHome() {
  return (
    <Layout reload>
      <div className="siteHeader">Aufmerksamkeit benötigt</div>
      <Spacer h={12}></Spacer>
      <AttentionItems></AttentionItems>
      <Spacer h={48}></Spacer>
      <div className="siteHeader">Ihre Kunden</div>
      <Spacer h={12}></Spacer>
      <PraxisList></PraxisList>
    </Layout>
  );
}
