import { useNavigate } from "@reach/router";
import React from "react";
import { FaPaperPlane } from "react-icons/fa";
import useApi from "../../hooks/use-api";
import useForm from "../../hooks/use-form";
import { ColorPicker, Input, RoundButton, Textarea } from "../form/form";
import Spacer from "../spacer/spacer";
import "./gkForm.scss";

export default function GkForm({
  endpoint = "createuser",
  defaults = {},
  title = "Neuen GK erstellen",
  button = "GK erstellen",
}) {
  defaults = {
    ...{
      email: "",
      role: "GROSSKUNDE",
      password: "123456",
      company: "",
      street: "",
      houseNumber: "",
      zipCode: "",

      telephone: "",
      delivery: "MANUAL",
      parentId: undefined,
      color: "#888",
      city: "",
      mailFooter: "",
    },
    ...defaults,
  };
  let { exec, status } = useApi({ endpoint });
  let nav = useNavigate();

  let [form, setForm] = useForm(defaults);
  //console.log(form);
  return (
    <div className="box praxisForm">
      <div className="siteHeader">{title}</div>
      <Input
        value={form.company}
        onChange={(s) => setForm("company", s)}
        label="Name des GK"
      ></Input>
      {endpoint === "createuser" && (
        <Input
          value={form.email}
          onChange={(s) => setForm("email", s)}
          label="E-Mail"
        ></Input>
      )}
      <Input
        value={form.telephone}
        onChange={(s) => setForm("telephone", s)}
        label="Telefon"
      ></Input>

      <div style={{ display: "flex" }}>
        <div style={{ flex: 3 }}>
          <Input
            value={form.street}
            onChange={(s) => setForm("street", s)}
            label="Straße"
          ></Input>
        </div>
        <Spacer w={12}></Spacer>
        <div style={{ flex: 1 }}>
          <Input
            value={form.houseNumber}
            onChange={(s) => setForm("houseNumber", s)}
            label="Hausnummer"
          ></Input>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Input
            value={form.zipCode}
            onChange={(s) => setForm("zipCode", s)}
            label="PLZ"
          ></Input>
        </div>
        <Spacer w={12}></Spacer>
        <div style={{ flex: 2 }}>
          <Input
            value={form.city}
            label="Stadt"
            onChange={(s) => setForm("city", s)}
          ></Input>
        </div>
      </div>
      <Textarea
        value={form.mailFooter}
        onChange={(s) => setForm("mailFooter", s)}
        label="E-Mail Footer"
      ></Textarea>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <ColorPicker
            value={form.color}
            onChange={(c) => {
              //console.log(c);
              setForm("color", c);
            }}
            label="Akzentfarbe"
          ></ColorPicker>
        </div>
      </div>
      <Spacer h={12}></Spacer>
      <RoundButton
        status={status}
        onClick={() => {
          exec(form).then((d) => {
            if (d.status === "OK") {
              nav("/singleGk/" + d.data.user.id);
            }
          });
        }}
      >
        <FaPaperPlane></FaPaperPlane>
        <Spacer w={8}></Spacer>
        {button}
      </RoundButton>
    </div>
  );
}
