//import { useNavigate } from "@reach/router";
import { Link } from "@reach/router";
import React from "react";
import { Input, RoundButton } from "../atoms/form/form";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";
import useApi from "../hooks/use-api";
import useForm from "../hooks/use-form";
import useUser from "../state/user";
import "./login.scss";

export default function Login() {
  let { setToken, setUser } = useUser();
  let { exec, status } = useApi({
    endpoint: "login",
    repeat: true,
    auth: false,
  });

  let [values, setForm] = useForm({
    email: "",
    password: "",
  });

  return (
    <Layout
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
      noNav
      noLogo
    >
      <div className="loginBox">
        <div className="loginTitle">
          Melden Sie sich an um den geschützten Bereich zu betreten.
        </div>
        <Input
          value={values.email}
          onChange={(t) => setForm("email", t)}
          label={"E-Mail"}
        ></Input>
        <Input
          value={values.password}
          onChange={(t) => setForm("password", t)}
          label={"Passwort"}
          type={"password"}
          onEnter={() => {
            exec(values).then((d) => {
              setToken(d.data.token);
              setUser(d.data.user);
            });
          }}
        ></Input>
        <Spacer h={8} />
        <RoundButton
          status={status}
          onClick={() => {
            exec(values).then((d) => {
              setToken(d.data.token);
              setUser(d.data.user);
            });
          }}
        >
          Einloggen
        </RoundButton>
        <Link
          style={{ textAlign: "center", marginTop: 24 }}
          to="forgotPassword"
        >
          Passwort vergessen
        </Link>
      </div>
    </Layout>
  );
}
