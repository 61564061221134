import React, { useState } from "react";
import { Input, RoundButton } from "../atoms/form/form";
import Layout from "../atoms/layout/layout";
import useApi from "../hooks/use-api";
export default function ResetPassword() {
  let { exec, status } = useApi({
    endpoint: "resetpassword",
    repeat: true,
  });
  let [newPasswordA, setNewPasswordA] = useState("");
  let [newPasswordB, setNewPasswordB] = useState("");
  let [token, setToken] = useState("");
  return (
    <Layout>
      <div className="siteHeader">Passwort zurücksetzen</div>
      <Input
        type="password"
        value={token}
        onChange={(s) => setToken(s)}
        label="Sicherheitscode"
      ></Input>
      <Input
        type="password"
        value={newPasswordA}
        onChange={(s) => setNewPasswordA(s)}
        label="Neues Passwort"
      ></Input>
      <Input
        type="password"
        value={newPasswordB}
        onChange={(s) => setNewPasswordB(s)}
        label="Neues Passwort wiederholen"
      ></Input>
      <RoundButton
        status={status}
        onClick={() =>
          exec({
            token,
            newPasswordA,
            newPasswordB,
          })
        }
      >
        Neues Passwort speichern
      </RoundButton>
    </Layout>
  );
}
