import { Link, useNavigate, useParams } from "@reach/router";
import React from "react";
import { RoundButton, Textarea } from "../atoms/form/form";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";
import useApi from "../hooks/use-api";
import useFile from "../hooks/use-file";
import useForm from "../hooks/use-form";
import useRequest from "../hooks/use-request";
import useUser from "../state/user";

import "./confirmPlanView.scss";
import { FileDownloader, typesToString } from "./singleClient";
export default function ConfirmPlanView() {
  let { client, plan } = useParams();
  let nav = useNavigate();
  client = parseInt(client);
  plan = parseInt(plan);
  let { id: userId } = useUser((s) => s.user);

  let { result } = useRequest("getstep", { id: plan });

  let planJson = {};
  try {
    planJson = result?.data?.step?.plan;
  } catch (e) {}

  //console.log(planJson, result?.data?.step?.files);

  let mainPDF = result?.data?.step?.files?.find((f) => f.relType === "PDF");
  let { fileUrl: pdfUrl, download } = useFile(mainPDF?.id, "application/pdf");
  let optionalPDF = result?.data?.step?.files?.find(
    (f) => f.relType === "OPTIONAL_PDF"
  );
  let { fileUrl: optionalPdfUrl, download: downloadOptional } = useFile(
    optionalPDF?.id,
    "application/pdf"
  );
  let zielFile = result?.data?.step?.files?.find(
    (f) => f.relType === "TREATMENT_TARGET"
  );

  let { exec } = useApi({
    endpoint: "updatestep",
    auth: true,
    repeat: true,
  });

  let { exec: addstep } = useApi({
    endpoint: "addsteptopatient",
    auth: true,
    repeat: true,
  });

  let [form, setForm] = useForm({ comment: "" });

  let isApproved = result?.data?.step?.approvalDate;
  let isRejected = result?.data?.step?.message;

  return (
    <Layout>
      <Spacer h={4}></Spacer>
      <Link to={"/client/" + client}>Zurück zum Kunde</Link>
      <Spacer h={12}></Spacer>
      <div className="siteHeader">
        {planJson && planJson.type && typesToString[planJson.type]}{" "}
        Behandlungsplan bestätigen
      </div>
      <br></br>
      <iframe
        title="Behandlungsplan PDF"
        className="pdfFrame"
        src={pdfUrl}
      ></iframe>
      <RoundButton
        onClick={() => download("plan_" + client + "_" + plan + ".pdf")}
      >
        PDF Herunterladen
      </RoundButton>
      {optionalPDF && (
        <>
          <br></br>
          <iframe
            title="2. Behandlungsplan PDF"
            className="pdfFrame"
            src={optionalPdfUrl}
          ></iframe>
          <RoundButton
        onClick={() => downloadOptional("plan2_" + client + "_" + plan + ".pdf")}
      >
        PDF Herunterladen
      </RoundButton>
        </>
      )}
      
      <div className="siteHeader">Behandlungsziel</div>
      {zielFile && (
        <FileDownloader
          type={zielFile.type}
          fileId={zielFile.id}
          path={zielFile.path}
        ></FileDownloader>
      )}

      <Spacer h={36}></Spacer>
      {result?.data && !isApproved && !isRejected && (
        <>
          <RoundButton
            /*onClick={() => {
        exec({ id: plan, approvalDate: new Date() }).then(() => {
          nav("/appointments/" + plan);
        });
        
      }}*/
            href={`/appointments/${client}/${plan}/${Math.max(
              planJson.OKSteps,
              planJson.UKSteps
            )}`}
            color="green"
          >
            Behandlungsplan freigeben
          </RoundButton>
          <Spacer h={12}></Spacer>
          <div style={{ textAlign: "center" }}>oder</div>
          <Spacer h={12}></Spacer>
          <Textarea
            value={form.comment}
            onChange={(t) => setForm("comment", t)}
            placeholder={"Kommentar"}
          ></Textarea>
          <RoundButton
            onClick={() => {
              exec({ id: plan, message: form.comment }).then(() => {
                addstep({
                  title: "Änderungswünsche",
                  type: "PLAN_CORRECTION",
                  pendingTask: true,
                  pending: true,
                  patientId: Number(client),
                  ownerId: userId,
                  message: form.comment,
                  dueDate: null,
                  approvalDate: null,
                  planId: planJson.id
                }).then(() => {
                  nav("/client/" + client);
                });
              });
            }}
          >
            Änderung anfordern
          </RoundButton>
          <Spacer h={12}></Spacer>
          <div
            style={{
              textAlign: "center",
              maxWidth: 400,
              margin: "0 auto",
              fontSize: "90%",
              fontWeight: "bold",
              color: "darkred",
            }}
          >
            Achtung: Bei Nichtumsetzung des Therapieplanes wird eine
            Aufwandspauschale von 50€ Netto pro geplantem Kiefer berechnet
          </div>
          <Spacer h={12}></Spacer>
          <RoundButton
            onClick={() => {
              exec({ id: plan, message: "[][]" + form.comment }).then(() => {
                addstep({
                  title: "Behandlungsplan abgelehnt",
                  type: "PLAN_DECLINED",
                  pendingTask: true,
                  pending: true,
                  patientId: Number(client),
                  ownerId: userId,
                  message: form.comment,
                  dueDate: null,
                  approvalDate: null,
                  planId: planJson.id
                }).then(() => {
                  nav("/client/" + client);
                });
              });
            }}
            color="red"
          >
            Behandlung abbrechen
          </RoundButton>
        </>
      )}
    </Layout>
  );
}
