import { Link } from "@reach/router";
import React, { useState } from "react";
import useRequest from "../../hooks/use-request";
import { Input, RoundButton } from "../form/form";
import Spacer from "../spacer/spacer";
import "./praxisList.scss";

export default function PraxisList({ parent }) {
  let { result } = useRequest(
    "getallcustomerusersofuser",
    {
      role: "PRAXIS",
      id: parent,
    },
    10
  );
  let [search, setSearch] = useState("");

  return (
    <div className="praxisList">
      <Input placeholder={"Suchen"} value={search} onChange={setSearch} />

      <RoundButton href={"/createPraxis" + (parent ? "/" + parent : "")}>
        Neue Praxis
      </RoundButton>
      <Spacer h={12}></Spacer>
      {result &&
        result.data &&
        result.data.customerUsers &&
        result.data.customerUsers
          .filter((p) => {
            return p.company.toLowerCase().includes(search.toLowerCase());
          })
          .map((p) => {
            return <Praxis key={p.id} data={p}></Praxis>;
          })}
    </div>
  );
}

function Praxis({ data }) {
  return (
    <Link to={"/praxis/" + data.id} className="box praxis">
      <div className="name">{data.company}</div>
      <div className="clients">
        Aufträge: {(data._count && data._count.patients) || 0}
      </div>
    </Link>
  );
}
