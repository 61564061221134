import React, { useState } from "react";
import { Input, RoundButton } from "../atoms/form/form";
import Layout from "../atoms/layout/layout";

import useApi from "../hooks/use-api";
export default function ForgotPassword() {
  let { exec, status } = useApi({
    endpoint: "forgotpassword",
    repeat: true,
  });
  let [email, setEmail] = useState("");

  return (
    <Layout>
      <div className="siteHeader">Passwort zurücksetzen</div>
      <Input
        value={email}
        onChange={(s) => setEmail(s)}
        label="Ihre E-Mail"
      ></Input>
      <RoundButton
        status={status}
        onClick={() =>
          exec({
            email,
          })
        }
      >
        Passwort zurücksetzen
      </RoundButton>
      {status === "done" && (
        <div style={{ marginTop: 12, textAlign: "center" }}>
          Bitte überprüfen Sie Ihren Posteingang
        </div>
      )}
    </Layout>
  );
}
