import { useParams } from "@reach/router";
import React from "react";
import Layout from "../atoms/layout/layout";
import useRequest from "../hooks/use-request";
import PraxisForm from "../atoms/praxisForm/praxisForm";
import EmailChanger from "../atoms/EmailChanger/EmailChanger";

export default function UpdatePraxis() {
  let { id } = useParams();
  id = parseInt(id);
  let { result } = useRequest("getuser", { id });
  return (
    <Layout>
      {result && result.data && (
        <>
          <PraxisForm
            endpoint={"updateuser"}
            title={"Praxis Aktualisieren"}
            button={"Änderungen speichern"}
            defaults={{
              ...result.data.user,
              id: result.data.user.id,
              Id: result.data.user.id,
            }}
          />
          <EmailChanger id={id} value={result.data.user.email}></EmailChanger>
        </>
      )}
    </Layout>
  );
}
