import { Link, useNavigate, useParams } from "@reach/router";
import React from "react";
import { RoundButton } from "../atoms/form/form";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";
import useApi from "../hooks/use-api";
import useFile from "../hooks/use-file";
import useRequest from "../hooks/use-request";
import useUser from "../state/user";

import "./confirmPlanView.scss";
export default function ConfirmKVView() {
  let { client, plan } = useParams();
  let nav = useNavigate();
  client = parseInt(client);
  plan = parseInt(plan);

  let { result } = useRequest("getstep", { id: plan });

  let { id: userId } = useUser((s) => s.user);

  let { fileUrl: KVUrl, download } = useFile(
    result?.data?.step?.files[0]?.id,
    "application/pdf"
  );

  let { exec } = useApi({
    endpoint: "updatestep",
    auth: true,
    repeat: true,
  });

  let { exec: addstep } = useApi({
    endpoint: "addsteptopatient",
    auth: true,
    repeat: true,
  });

  let isApproved = result?.data?.step?.approvalDate;
  let isRejected = result?.data?.step?.message;

  return (
    <Layout>
      <Spacer h={4}></Spacer>
      <Link to={"/client/" + client}>Zurück zum Kunde</Link>
      <Spacer h={12}></Spacer>
      <div className="siteHeader">Kostenvoranschlag bestätigen</div>
      <iframe
        title="Kostenvoranschlag PDF"
        className="pdfFrame"
        src={KVUrl}
      ></iframe>
      <RoundButton
        onClick={() => download("kv_" + client + "_" + plan + ".pdf")}
      >
        PDF Herunterladen
      </RoundButton>

      <Spacer h={36}></Spacer>
      {!isApproved && !isRejected && (
        <>
          <RoundButton
            onClick={() => {
              addstep({
                title: "Kostenvoranschlag freigegeben",
                type: "KV_ACCEPTED",
                pendingTask: true,
                pending: true,
                patientId: Number(client),
                ownerId: userId,
                message: "",
                dueDate: null,
                approvalDate: null,
              }).then(() => {
                exec({ id: plan, approvalDate: new Date() }).then(async () => {
                  nav("/client/" + client);
                });
              });
            }}
            color="green"
          >
            Kostenvoranschlag freigeben
          </RoundButton>
          <Spacer h={12}></Spacer>
          <div style={{ textAlign: "center" }}>oder</div>
          <Spacer h={12}></Spacer>

          <RoundButton
            onClick={() => {
              exec({ id: plan, message: "[][]" }).then(() => {
                addstep({
                  title: "Kostenvoranschlag abgelehnt",
                  type: "KV_DECLINED",
                  pendingTask: true,
                  pending: true,
                  patientId: Number(client),
                  ownerId: userId,
                  message: "",
                  dueDate: null,
                  approvalDate: null,
                 
                }).then(() => {
                  nav("/client/" + client);
                });
              });
            }}
            color="red"
          >
            Behandlung abbrechen
          </RoundButton>
        </>
      )}
    </Layout>
  );
}
