import React, { useState } from "react";
import "./nav.scss";
import { useDetectClickOutside } from "react-detect-click-outside";
import {
  FaArrowLeft,
  FaCalendar,
  FaClock,
  FaCog,
  FaHome,
  FaTv,
} from "react-icons/fa";
import { Link, useNavigate } from "@reach/router";
import useUser from "../../state/user";

export default function Nav() {
  const [navOpen, setNavOpen] = useState(false);
  let { user, logout } = useUser();
  let nav = useNavigate();
  const ref = useDetectClickOutside({
    onTriggered: () => setNavOpen(false),
  });

  return (
    <div className="nav" ref={ref}>
      <div
        className="userArea"
        onClick={() => {
          setNavOpen(!navOpen);
        }}
      >
        <div className="icon"></div>
        <div className="loggedInUser">{user.company || user.email}</div>
      </div>
      {navOpen && (
        <div className="dropDown">
          <Link to={"/"} className="item">
            <div className="icon">
              <FaHome></FaHome>
            </div>
            <div className="text">Home</div>
          </Link>
          {user.role === "ADMIN" && (
            <>
              <Link to={"/daily"} className="item">
                <div className="icon">
                  <FaCalendar></FaCalendar>
                </div>
                <div className="text">Daily</div>
              </Link>
              <Link to={"/tv"} className="item">
                <div className="icon">
                  <FaTv></FaTv>
                </div>
                <div className="text">TV</div>
              </Link>
            </>
          )}
          {user.role === "GROSSKUNDE" && <></>}
          {user.role === "PRAXIS" && (
            <>
              <Link to={"/archiv"} className="item">
                <div className="icon">
                  <FaClock></FaClock>
                </div>
                <div className="text">Archiv</div>
              </Link>
            </>
          )}
          <Link to={"/updatePassword"} className="item">
            <div className="icon">
              <FaCog></FaCog>
            </div>
            <div className="text">Passwort ändern</div>
          </Link>

          <div
            onClick={() => {
              logout();
              nav("/");
            }}
            className="item"
          >
            <div className="icon">
              <FaArrowLeft></FaArrowLeft>
            </div>
            <div className="text">Logout</div>
          </div>
        </div>
      )}
    </div>
  );
}
