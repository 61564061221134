import { Link, useParams } from "@reach/router";
import React from "react";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";
import AppointmentMaker from "../atoms/appointmentMaker/appointmentMaker";

import "./confirmPlanView.scss";
export default function Appointments() {
  let { client, plan, steps, from } = useParams();
  client = parseInt(client);
  plan = parseInt(plan);
  steps = parseInt(steps);
  from = parseInt(from);
  return (
    <Layout>
      <Spacer h={4}></Spacer>
      <Link to={"/client/" + client}>Zurück zum Kunde</Link>
      <div className="siteHeader">Ersten Liefertermin wählen</div>
      <AppointmentMaker
        steps={steps}
        client={client}
        plan={plan}
        from={from||1}
      />
    </Layout>
  );
}
