import React from "react";
import useApi from "../../hooks/use-api";
import useUser from "../../state/user";
import { RoundButton, Textarea } from "../form/form";
import Spacer from "../spacer/spacer";
import "./MessageSender.scss";

export default function MessageSender({ patientId }) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  let { id: loggedInUserId } = useUser((s) => s.user);
  let { exec, status } = useApi({ endpoint: "addsteptopatient", repeat: true });
  if (!open) {
    return (
      <RoundButton onClick={() => setOpen(true)}>
        Nachricht hinterlassen | Abholung mit Kommentar beauftragen
      </RoundButton>
    );
  }
  if (status === "done") {
    return <div className="box messageConfirm">Ihre Nachricht wurde versendet.</div>;
  }
  return (
    <div className="box">
      <Textarea
        autofocus
        placeholder="Ihre Nachricht"
        value={message}
        onChange={(t) => setMessage(t)}
      ></Textarea>
      <RoundButton
        color="green"
        status={status}
        onClick={() => {
          exec({
            title: "Nachricht",
            type: "MESSAGE",
            message,
            pendingTask: true,
            pending: true,
            patientId: Number(patientId),
            owner: Number(loggedInUserId),
          });
        }}
      >
        Abschicken
      </RoundButton>
      <Spacer h={8}></Spacer>
      <RoundButton onClick={() => setOpen(false)} color="red">
        Abbrechen
      </RoundButton>
    </div>
  );
}
