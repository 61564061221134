import React, { useEffect, useState } from "react";
import useApi from "../../hooks/use-api";
import { ActionLink } from "../../screens/singleClient";
import { Input, RoundButton } from "../form/form";
import "./WorkerChanger.scss";

export default function WorkerChanger({ defaultWorker, id }) {
  const [worker, setWorker] = useState(defaultWorker);
  useEffect(() => {
    setWorker(defaultWorker);
  }, [defaultWorker, setWorker]);
  let { exec, status } = useApi({ endpoint: "updatepatient", repeat:true });
  let [open, setOpen] = useState(false);
  return (
    <div className="box WorkerChanger">
      {!open && (
        <>
          {!worker && <div>Noch kein Mitarbeiter zugewiesen</div>}
          {defaultWorker && <div>Wird bearbeitet von: {defaultWorker}</div>}
          <ActionLink onClick={() => setOpen(true)}>Bearbeiten</ActionLink>
        </>
      )}
      {open && (
        <>
          <Input value={worker} onChange={setWorker}></Input>
          <RoundButton
            status={status}
            onClick={() => {
              exec({ id: id, worker: worker });
            }}
          >
            Änderungen speichern
          </RoundButton>
        </>
      )}
    </div>
  );
}
