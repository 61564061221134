import { Link, useParams } from "@reach/router";
import React from "react";
import Layout from "../atoms/layout/layout";
import UploadPlan from "../atoms/uploadPlan/uploadPlan";
import Spacer from "../atoms/spacer/spacer";
import "./singleClient.scss";

export default function UploadPlanView() {
  let { id } = useParams();
  return (
    <Layout>
      <Spacer h={4}></Spacer>
      <Link to={"/client/" + id}>Zurück zum Kunde</Link>
      <Spacer h={12}></Spacer>
      <div className="siteHeader">Behandlungsplan hochladen</div>
      <UploadPlan patientId={id}></UploadPlan>
    </Layout>
  );
}
