import axios from "axios";
import { useState } from "react";
import useUser from "../state/user";
import apiUrl from "./api-url";

const baseUrl = apiUrl;
//const baseUrl = "http://localhost:3333/";
export { baseUrl };

export function useApi({ endpoint, repeat = false, auth = true }) {
  let [theState, setTheState] = useState({
    status: "start",
    error: null,
    result: null,
  });

  let token = useUser((s) => s.token);
  let exec = (data) => {
    return new Promise((resolve, reject) => {
      if (["loading"].includes(theState.status)) {
        reject("already-loading");
        return;
      }
      if (theState.status === "done" && !repeat) {
        reject("already-done");
        return;
      }

      setTheState({ ...theState, status: "loading", error: null });
      let options = {};
      if (token) {
        options.headers = {
          Authorization: "Bearer " + token,
        };
      }

      //post

      return axios
        .post(baseUrl + endpoint, data, options)
        .then((d) => {
          if (d.data.status === "OK") {
            setTheState({
              status: "done",
              error: null,
              result: d.data,
            });

            resolve(d.data);
          } else {
            setTheState({
              status: "error",
              error: d.data.message,
              result: null,
            });
            reject(d.data);
          }
        })
        .catch((e) => {
          //console.log(e);
          setTheState({
            status: "error",
            error: "Ein Fehler ist aufgetreten.",
            result: null,
          });
          reject(e);
        });

      /*
      method(data)
        .then((r) => {
          setResult(r.data);
          setError(null);
          setStatus("done");

          resolve(r.data);
        })
        .catch((r) => {
          console.log(r);
          //history.push("/login");
          setError("Unerwarteter Fehler. Bitte überprüfen Sie Ihre Eingaben.");
          setStatus("error");
          reject(r);
        });
        */
    });
  };

  const reset = () => {
    setTheState({
      status: "start",
      error: null,
      result: null,
    });
  };

  return {
    exec,
    reset,
    status: theState.status,
    result: theState.result,
    error: theState.error,
  };
}

export default useApi;
