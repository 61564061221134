import { Link, useParams } from "@reach/router";
import React, { useCallback, useState } from "react";
import { FaCheck, FaCircle } from "react-icons/fa";
import { ClientListItem } from "../atoms/clientList/clientList";
import { RoundButton } from "../atoms/form/form";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";
import ClientForm from "../atoms/clientForm/clientForm";
import "./singleClient.scss";
import useRequest from "../hooks/use-request";
import useUser from "../state/user";
import moment from "moment";
import MessageSender from "../atoms/MessageSender/MessageSender";
import useFile from "../hooks/use-file";
import useApi from "../hooks/use-api";
import Loading from "react-loading";
import WorkerChanger from "../atoms/WorkerChanger/WorkerChanger";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export const typesToString = {
  RETAINER: "Retainer",
  SCHIENE: "Folgeschiene",
  TITAINER: "Titainer",
  INITIAL: "Erstbehandlung",
  CORRECTION: "Korrektur",
};

export default function SingleClient() {
  let { id } = useParams();
  id = parseInt(id);
  let { result } = useRequest("getpatient", { id });
  let { result: steps } = useRequest("getstepsofpatient", { id });
  //console.log(steps);
  let [writeComment, setWriteComment] = useState(false);

  let user = useUser((u) => u.user);

  let data = (result && result.data.patient) || {};
  let stepList =
    (steps &&
      steps.data.steps.sort(
        (a, b) =>
          new Date(b.dueDate || b.createdAt).getTime() -
          new Date(a.dueDate || a.createdAt).getTime()
      )) ||
    [];
  //console.log(steps, stepList);

  let { exec, status } = useApi({ endpoint: "updatepatient", repeat: false });
  let { exec: addStep, status: addStatus } = useApi({
    endpoint: "addsteptopatient",
    repeat: true,
  });
  let { exec: updateStep, status: updateStatus } = useApi({
    endpoint: "updatestep",
    repeat: true,
  });

  const skipStep = useCallback(
    async (id, side) => {
      //get step
      let stepList =
        (steps &&
          steps.data.steps
            .filter((s) => s.type === "PLAN_STEP" && s.title)
            .map((s) => {
              try {
                let extraData = JSON.parse(s.message);
                return { ...s, extraData };
              } catch (e) {}
              return s;
            })
            .sort(
              (a, b) =>
                new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
            )) ||
        [];
      let selectedStep = stepList.find((s) => s.id === id);
      stepList = stepList.filter(
        (s) =>
          s.extraData.plan === selectedStep.extraData.plan &&
          new Date(s.dueDate).getTime() >=
            new Date(selectedStep.dueDate).getTime()
      );
      //console.log(selectedStep, stepList);
      //move all sides ahead 1
      //maybe create a new step, too..
      let inHead = null;
      let lastDate = null;
      stepList = stepList.map((s, i) => {
        if (i === 0) {
          inHead = s.extraData[side];
          lastDate = s.dueDate;
          //console.log("in head: " + inHead);
          return { ...s, extraData: { ...s.extraData, [side]: undefined } };
        }
        if (inHead) {
          let tmp = inHead;
          inHead = s.extraData[side];
          lastDate = s.dueDate;
          return { ...s, extraData: { ...s.extraData, [side]: tmp } };
        }
        return s;
      });
      //update All Steps
      //console.log(inHead, stepList);
      for (let i = 0; i < stepList.length; i++) {
        let newStep = stepList[i];
        newStep.title =
          (newStep.extraData.OKSteps
            ? `OK: ${newStep.extraData.OKSteps.join(" & ")}`
            : "") +
          (newStep.extraData.UKSteps && newStep.extraData.OKSteps ? ", " : "") +
          (newStep.extraData.UKSteps
            ? `UK: ${newStep.extraData.UKSteps.join(" & ")}`
            : "");
        await updateStep({
          id: newStep.id,
          title: newStep.title,
          message: JSON.stringify(newStep.extraData),
        });
      }
      if (inHead) {
        ////console.log("gotta create a new step, damn!");
        let newStep = {
          patientId: selectedStep.patientId,
          type: "PLAN_STEP",
          dueDate: new moment(lastDate).add(4, "weeks").toDate(),
          message: JSON.stringify({ plan: selectedStep.plan, [side]: inHead }),
          trackingInfo: "",
          title:
            (side === "OKSteps" ? `OK: ${inHead.join(" & ")}` : "") +
            (side === "UKSteps" ? `UK: ${inHead.join(" & ")}` : ""),
        };
        //console.log({ newStep });
        await addStep(newStep);
      }
      window.location.reload();
    },
    [addStep, updateStep, steps]
  );
  return (
    <Layout>
      <>
        <Spacer h={4}></Spacer>
        <div className="flex">
          {data && data.parentId !== 1 && (
            <Link to={"/praxis/" + data.parentId}>Zur Praxis</Link>
          )}
          <div className="flex1"></div>
          {user.role === "ADMIN" && (
            <Link to={"/laufzettel/" + data.parentId + "/" + id}>
              Druckansicht
            </Link>
          )}
        </div>
      </>
      <Spacer h={12}></Spacer>
      <ClientListItem
        name={data.firstName + " " + data.lastName}
        id={data.id}
        cid={data.cid}
        step={data.step}
        noClick
      ></ClientListItem>

      {user.role === "ADMIN" && (
        <>
          <WorkerChanger id={id} defaultWorker={data.worker}></WorkerChanger>
        </>
      )}
      <div className="comment box">
        {!writeComment && (
          <>
            <b>Kommentar</b>
            <br />
            {data.comment}
            <Spacer h={12}></Spacer>
            <RoundButton
              onClick={() => setWriteComment(true)}
              style={{ flex: 1 }}
            >
              Kommentar aktualisieren
            </RoundButton>
          </>
        )}
        {data.id && writeComment && (
          <ClientForm
            endpoint={"updatepatient"}
            title={"Kommentar Aktualisieren"}
            button={"Änderungen speichern"}
            commentOnly
            hideShipping
            onComplete={() => {
              setWriteComment(false);
            }}
            defaults={{
              ...data,
              id: data.id,
              Id: data.id,
            }}
          />
        )}
      </div>

      <div className="" style={{ display: "flex", gap: 12 }}>
        <RoundButton href={"/updateClient/" + id} style={{ flex: 1 }}>
          Aktualisieren
        </RoundButton>
        {data.archived ? (
          <RoundButton
            status={status}
            onClick={() => {
              exec({ archived: false, id: data.id });
            }}
            style={{ flex: 1 }}
          >
            Unarchivieren
          </RoundButton>
        ) : (
          <RoundButton
            status={status}
            onClick={() => {
              exec({ archived: true, id: data.id });
            }}
            style={{ flex: 1 }}
          >
            Archivieren
          </RoundButton>
        )}
        <RoundButton href={"/uploadScans/" + data.id} style={{ flex: 1 }}>
          Neuen Abdruck hochladen
        </RoundButton>
      </div>
      {(user.role === "GROSSKUNDE" || user.role === "ADMIN") && (
        <>
          <Spacer h={12}></Spacer>
          <RoundButton
            color="green"
            href={"/uploadKV/" + id}
            style={{ flex: 1 }}
          >
            Kostenvoranschlag hochladen
          </RoundButton>
        </>
      )}
      {user.role === "ADMIN" && (
        <>
          <Spacer h={12}></Spacer>
          <RoundButton
            color="green"
            href={"/uploadPlan/" + id}
            style={{ flex: 1 }}
          >
            Behandlungsplan hochladen
          </RoundButton>
        </>
      )}
      <Spacer h={12}></Spacer>
      <MessageSender patientId={id}></MessageSender>
      <Spacer h={12}></Spacer>
    
     
      <div className="clientSteps">
        {stepList &&
          stepList.map((s) => {
            return (
              <ClientStep
                type={s.type}
                stepId={s.id}
                text={s.title}
                date={s.dueDate || s.createdAt}
                message={s.message}
                tracking={s.trackingInfo}
                linkText={s.linkText}
                onConfirm={s.confirm ? () => {} : null}
                data={s}
                skipStep={skipStep}
                addStatus={addStatus}
                _updateStatus={updateStatus}
              ></ClientStep>
            );
          })}
      </div>
    </Layout>
  );
}

function ClientStep({
  type,
  text,
  date,
  linkText,
  onConfirm,
  message,
  tracking,
  stepId,
  data,
  skipStep,
  addStatus,
  _updateStatus,
}) {
  let {
    user: { role, id: userId },
  } = useUser();
  //console.log(role, data);
  let { id } = useParams();
  const { exec, status: updateStatus } = useApi({
    endpoint: "updatestep",
    repeat: true,
  });
  let extraData = {};
  if (data && data.plan && ["PLAN"].includes(type)) {
    extraData = data.plan;
  }

  if (message && ["PLAN_STEP"].includes(type)) {
    try {
      extraData = JSON.parse(message);
    } catch (e) {
      //console.log(e);
    }
  }
  //console.log(data);
  //console.log({ extraData });
  let isPast = moment(date).isSameOrBefore(moment(), "day");
  return (
    <div
      className={
        "clientStep " +
        "stepType" +
        type +
        (data.done ? "done " : " ") +
        (data.aborted ? "aborted " : " ") +
        (data.pendingTask ? "pending " : " ")
      }
    >
      <div className="styler">
        <FaCircle></FaCircle>
      </div>

      <div className="text">
        <div className="date">{date && moment(date).format("DD.MM.YY")}</div>

        {extraData.type ? (
          <b>{typesToString[extraData.type]}</b>
        ) : data.plan && data.plan.type ? (
          typesToString[data.plan.type]
        ) : (
          ""
        )}
        <b>{text}</b>
        {linkText && (
          <Link className="styled" to="">
            {linkText}
          </Link>
        )}
      </div>
      {type === "PLAN_STEP" && (
        <>
          {data.trackingInfo && (
            <div className="actionLinks" style={{ whiteSpace: "wrap" }}>
              Tracking Info:
              <a
                href={
                  "https://www.general-overnight.com/deu_de/sendungsverfolgung.html?reference=" +
                  data.trackingInfo
                }
                target="_blank"
                rel="noreferrer"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                Sendungsverfolgung
              </a>
            </div>
          )}
          {!data.done && (
            <>
              {_updateStatus !== "loading" &&
                addStatus !== "loading" &&
                !isPast && (
                  <div className="actionLinks">
                    {extraData.OKSteps && (
                      <ActionLink onClick={() => skipStep(data.id, "OKSteps")}>
                        OK aussetzen
                      </ActionLink>
                    )}
                    {extraData.UKSteps && (
                      <ActionLink onClick={() => skipStep(data.id, "UKSteps")}>
                        UK aussetzen
                      </ActionLink>
                    )}
                  </div>
                )}
            </>
          )}
          <div className="actionLinks">
            {role === "ADMIN" && (
              <ActionLink href={"/step/" + data.id}>Produktion</ActionLink>
            )}
            {!data.done && (role === "ADMIN" || !isPast) && (
              <>
                <ActionLink href={"/reschedule/" + id}>Verschieben</ActionLink>
              </>
            )}
            {role === "ADMIN" && (
              <ActionLink
                status={updateStatus}
                onClick={() => {
                  confirmAlert({
                    title: "Stornieren?",
                    message: "Sind Sie sicher?",
                    buttons: [
                      {
                        label: "Ja, stornieren!",
                        onClick: () =>
                          exec({
                            id: data.id,
                            aborted: true,
                            done: true,
                            pending: false,
                            pendingTask: false,
                          }),
                      },
                      {
                        label: "Nein",
                        onClick: () => {},
                      },
                    ],
                  });
                }}
              >
                Stornieren
              </ActionLink>
            )}
          </div>
        </>
      )}

      {false && tracking && (
        <div className="buttons">
          <div>Tracking:</div>
          {tracking}
        </div>
      )}
      {(type === "NEW_SCANS" || type === "WAIT") && (
        <div className="buttons">
          {data.files.map((f) => {
            //console.log(f);
            return (
              <FileDownloader
                type={f.type}
                fileId={f.id}
                path={f.path}
              ></FileDownloader>
            );
          })}
        </div>
      )}
      {type === "MESSAGE" && (
        <>
          <div className="message">{message}</div>

          <div className="buttons">
            {data.pendingTask && data.ownerId !== userId && (
              <RoundButton
                status={updateStatus}
                onClick={() => {
                  exec({ id: data.id, pendingTask: false });
                }}
              >
                Als gelesen markieren
              </RoundButton>
            )}
          </div>
        </>
      )}
      {type === "KV" && (
        <>
          <div className="buttons">
            <RoundButton href={"/confirmKV/" + id + "/" + stepId}>
              Ansehen
            </RoundButton>
          </div>
        </>
      )}
      {type === "PLAN" && (
        <>
          <div className="buttons">
            {data.message &&
              (data.message.startsWith("[][]") ? (
                <div>
                  Abgelehnt:
                  <Spacer h={5}></Spacer>
                  <div className="box">
                    {data.message.replace(/\[\]\[\]/g, "")}
                  </div>
                </div>
              ) : (
                <div>
                  Änderungen angefordert:
                  <Spacer h={5}></Spacer>
                  <div className="box">{data.message}</div>
                </div>
              ))}
          </div>
          <div className="buttons">
            <RoundButton href={"/confirmPlan/" + id + "/" + stepId}>
              Ansehen
            </RoundButton>
          </div>
        </>
      )}
    </div>
  );
}


export function FileDownloader({ fileId, type, path }) {
  let { download } = useFile(fileId, type);
  let fileEnding = "";
  if (type === "image/jpeg") {
    fileEnding = ".jpg";
  }
  if (type === "image/png") {
    fileEnding = ".png";
  }
  if (type === "image/gif") {
    fileEnding = ".gif";
  }
  if (type === "application/pdf") {
    fileEnding = ".pdf";
  }
  if (type === "application/stl") {
    fileEnding = ".stl";
  }
  if (type === "application/x-msdownload") {
    fileEnding = ".exe";
  }
  if (!fileEnding && path && path.split) {
    fileEnding = "." + path.split(".")[1];
  }
  return (
    <RoundButton onClick={() => download("downloaded_file" + fileEnding)}>
      Download
    </RoundButton>
  );
}

export function ActionLink({ href, onClick = () => {}, children, status }) {
  const Icon = () => (status === "done" ? <FaCheck></FaCheck> : null);
  let Contents = () => (
    <>
      {children} {<Icon></Icon>}
      {status === "loading" && (
        <Loading height={12} type="spin" width={12} color="black"></Loading>
      )}
    </>
  );
  if (href) {
    return (
      <Link className="actionLink" to={href}>
        <Contents></Contents>
      </Link>
    );
  }
  return (
    <span className="actionLink" onClick={onClick}>
      <Contents></Contents>
    </span>
  );
}
