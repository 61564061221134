import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import useUser from "../state/user";
import { baseUrl } from "./use-api";

export default function useFile(fileId, type) {
  let token = useUser((s) => s.token);
  let [fileUrl, setFileUrl] = useState();
  useEffect(() => {
    fileId &&
      axios({
        url: baseUrl + "download?id=" + fileId, //your url
        method: "POST",
        responseType: "blob", // important
        headers: {
          Authorization: "Bearer " + token,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type })
        );
        //console.log(url);
        setFileUrl(url);
      });
  }, [fileId, token, setFileUrl, type]);

  let download = useCallback(
    (fileName = "file") => {
      let link = document.createElement("a");
      link.download = fileName;
      link.href = fileUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    [fileUrl]
  );

  return { fileUrl, download };
}
