import { /*useNavigate ,*/ useParams } from "@reach/router";
import React, { useEffect } from "react";
import { ButtonSelector, Input, RoundButton } from "../atoms/form/form";
import Layout from "../atoms/layout/layout";
//import Spacer from "../atoms/spacer/spacer";
import useApi from "../hooks/use-api";
import useForm from "../hooks/use-form";
import useRequest from "../hooks/use-request";
import { ActionLink, typesToString } from "./singleClient";
import Spacer from "../atoms/spacer/spacer";

export default function SingleStep() {
  let { id } = useParams();
  let [form, setForm] = useForm({
    tracking: "",
    worker: "",
    done: false,
    printed: false,
  });
  id = parseInt(id);
  let { result: stepData } = useRequest("getstep", { id: id });
  let { exec, status } = useApi({ endpoint: "updatestep", repeat: true });
  let data = (stepData && stepData.data && stepData.data.step) || {};
  let planData = {};
  try {
    planData = JSON.parse(data.message);
  } catch (e) {}

  //const nav = useNavigate();
  useEffect(() => {
    setForm("worker", data.worker || (data.patient && data.patient.worker));
    setForm("tracking", data.trackingInfo);
    setForm("done", data.done);
    setForm("printed", data.printed);
  }, [
    setForm,
    stepData,
    data.done,
    data.trackingInfo,
    data.worker,
    data.patient,
    data.printed,
  ]);
  return (
    <Layout>
      <div className="siteHeader">Fertigung</div>
      {data.patient && (
        <div>
          <ActionLink href={"/client/" + data.patient.id}>
            Kunde #{data.patient.id} / {data.patient.cid} (
            {data.patient.lastName})
          </ActionLink>
        </div>
      )}
      {data.patient && (
        <div>
          <ActionLink href={"/praxis/" + data.patient.parent.id}>
            Praxis {data.patient.parent.company}
          </ActionLink>
        </div>
      )}
      {data.patient && (
        <div>
          <ActionLink onClick={() => window.history.back()}>Zurück</ActionLink>
        </div>
      )}
      <Spacer h={12}></Spacer>
      {data.plan && data.plan.type && typesToString[data.plan.type]}
      <br></br>
      {data.title}
      <ActionLink href={"/confirmPlan/" + data.patientId + "/" + planData.plan}>
        Behandlungsplan ansehen
      </ActionLink>
      <Spacer h={24}></Spacer>
      <Input
        value={form.worker}
        onChange={(s) => setForm("worker", s)}
        label="Bearbeiter"
      ></Input>
      <Input
        value={form.tracking}
        onChange={(s) => setForm("tracking", s)}
        label="Trackingnummer"
      ></Input>
      <ButtonSelector
        value={form.printed}
        onChange={(s) => setForm("printed", s)}
        options={[
          { id: false, label: "NICHT GEDRUCK" },
          { id: true, label: "GEDRUCKT" },
        ]}
      ></ButtonSelector>
      <Spacer h={24}></Spacer>
      <ButtonSelector
        value={form.done}
        onChange={(s) => setForm("done", s)}
        options={[
          { id: false, label: "NICHT FERTIG" },
          { id: true, label: "FERTIG" },
        ]}
      ></ButtonSelector>
      <Spacer h={24}></Spacer>

      <RoundButton
        onClick={() => {
          exec({
            id,
            done: form.done,
            trackingInfo: form.tracking,
            worker: form.worker,
            printed: form.printed,
          }).then(() => {
            window.history.back();
          });
        }}
        status={status}
      >
        Änderungen speichern
      </RoundButton>
    </Layout>
  );
}
