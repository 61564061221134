import React from "react";
import ClientList from "../atoms/clientList/clientList";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";
import { AttentionItems } from "./dailyView";
export default function Home() {
  return (
    <Layout reload>
      <AttentionItems></AttentionItems>
      <Spacer h={12}></Spacer>

      <ClientList></ClientList>
    </Layout>
  );
}
